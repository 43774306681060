export const jobsList = [
    {
        id:1,
        title:"Personal Assistant and HR/Administrative",
        experience:"1-5 years of experience",
        content:"As a CEO Personal Assistant and HR/Administrative professional for a software company, you would play a key role in supporting the CEO and ensuring the smooth operation of the company. Your responsibilities would include managing the CEO's calendar and scheduling appointments, organizing meetings, and providing administrative support. You would also oversee HR activities such as recruitment, onboarding, and employee relations, maintain employee records, and ensure compliance with HR policies and procedures. In addition, you would be responsible for managing office operations, handling financial tasks, and supporting the development and implementation of company policies and procedures. Overall, your role would be critical in maintaining effective communication and coordination within the company."
    },
    // {
    //     id:2,
    //     title:"Java Developer",
    //     experience:"1-2 years of experience",
    //     content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ...Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ...Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown."
    // }
]