import React from 'react';
import './home.style.css';
import { InstagramLogo,LinkedinLogo,TwitterLogo,FacebookLogo ,X} from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom';
import BackGroundGrid from '../../components/BackgroundGrid';
import HomeLogoBoxes from '../../components/HomeLogoBoxes';
import GridLines from 'react-gridlines';
const HomePage = () => {
    const navigate = useNavigate();
    const onJoinUsClick = ()=>{
        navigate("join-us")
    }
    const onNavLinkClick = (page)=>{
        navigate(page)
    }
    const onSocialLinkClick = (socialApp)=>{
        if(socialApp === "INSTA"){
            window.open("https://www.instagram.com/co.skyland/");
        }
        else if(socialApp === "FACEBOOK"){
            window.open("https://www.facebook.com/profile.php?id=61550844929943&is_tour_dismissed=true");
        } else if(socialApp === "X"){
            window.open("https://twitter.com/skyland2023");
        }
    }
    
    return(
        <div className='homemaincon'>
            <GridLines lineColor="#171717" lineColor2="#171717" className="grid-area" cellWidth={30} strokeWidth={2} cellWidth2={31}>
        <div className='home-contaier' >
            <img className='home-logo-img' src={require('../../assets/logo.png')} />
            <div className='future-text'>
                <p >The future</p>
                <p >of digital</p>
                <p >connection</p>
                <div className='home-join-container'>
                    <div className='home-join-container-text'>
                        {/* <label className='home-join-text'>Join our team! 🫵</label> */}
                        {/* <label onClick={onJoinUsClick} className='home-see-all-text'>See all openings</label> */}
                        <label onClick={()=>onNavLinkClick('about-us')} className='home-see-all-text'>About Us</label>
                        <label onClick={()=>onNavLinkClick('terms-of-use')} className='home-see-all-text'>Terms of Use</label>
                        <label onClick={()=>onNavLinkClick('privacy-policy')} className='home-see-all-text'>Privacy Policy</label>
                        <label onClick={()=>onNavLinkClick('user-agreement')} className='home-see-all-text'>User Agreement</label>
                    </div>
                    <div className='social-icons-container'>
                        <InstagramLogo className='social-icon' onClick={()=>onSocialLinkClick('INSTA')} weight="fill" size={24} />
                        <FacebookLogo className='social-icon' onClick={()=>onSocialLinkClick('FACEBOOK')} style={{marginLeft:32}} weight="fill" size={24} />
                        <X className='social-icon' onClick={()=>onSocialLinkClick('X')} style={{marginLeft:32}}  weight="fill" size={24} />
                    </div>
                </div>
            </div>
        </div>
        </GridLines>
        <HomeLogoBoxes />
        </div>
    )
}


export default HomePage;