import React,{useRef,useEffect} from 'react';
import './components.styles.css';

const excludeBox = [
    '0,0','0,1','0,2','0,3','0,4','0,5',  '0,13','0,14','0,15','0,16','0,17','0,18',
    '1,0','1,1','1,2','1,3',  '1,15','1,16','1,17','1,18',
    '2,0','2,1' ,'2,17','2,18',
    '3,0','3,1' ,'3,17','3,18',
    '4,0','4,18',
    '5,0','5,18',

    '13,0','13,18',
    '14,0','14,18',
    '15,0','15,1' ,'15,17','15,18',
    '16,0','16,1' ,'16,17','16,18',
    '17,0','17,1','17,2','17,3',  '17,15','17,16','17,17','17,18',
    '18,0','18,1','18,2','18,3','18,4','18,5',  '18,13','18,14','18,15','18,16','18,17','18,18',

]

const LogoBox = () =>{
    const canvasRef = useRef(null);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    console.log("window: ",windowSize )
    let Boxes = [];
    // for(let h = 0; h < 570 ; h+= 30 ){  //height
    //     for(let w = 0; w < 570 ; w+= 30 ){ //width
    //         if(!excludeBox.includes(`${h/30},${w/30}`)){
    //             // particleArray.push(new Particle(w * 2.2 ,h *2.2));
    //             Boxes.push(<div style={{
    //                 position:'absolute',
    //                 top:h,
    //                 left:w,
    //             }} className='signle-logo-box'  ><p style={{fontSize:9}}></p></div>)
    //         }
    //     }  
    // } 
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 970;
        canvas.height = 610;
        let particleArray = [];
        let adjustX = 100; 
        let adjustY = 100;
        const mouse = {
            x: null,
            y: null,
            raduis: 50
        }
        window.addEventListener('mousemove', function(event){
            mouse.x = event.x -  720;
            mouse.y = event.y - 91;
        })
        class Particle {
            constructor(x,y){
                this.x = x ;
                this.y = y;
                this.baseX = this.x;
                this.baseY = this.y;
                this.density = (Math.random() * 15) + 1;
            }
            draw(){
                ctx.fillStyle = '#00D3E2CC';
                ctx.beginPath();
                ctx.rect(this.x, this.y, 29, 29);
                ctx.closePath();
                ctx.fill();
            }
            update(){
                let dx = mouse.x - this.x;
                let dy = mouse.y - this.y;
                let distance = Math.sqrt(dx * dx + dy * dy);
                let forceDirectionX = dx / distance;
                let forceDirectionY = dy / distance;
                let maxDistance = mouse.raduis ;
                let force = (maxDistance - distance ) / maxDistance;
                let directionX = forceDirectionX * force * this.density;
                let directionY = forceDirectionY * force * this.density;
                if(distance < mouse.raduis){
                    this.x -= directionX;
                    this.y -= directionY;
                }else{
                    if(this.x !== this.baseX){
                        let dx = this.x - this.baseX;
                        this.x -= dx/5;
                    }if(this.y !== this.baseY){
                        let dy = this.y - this.baseY;
                        this.y -= dy/5;
                    }
                }
            }
        }
        function init(){
            particleArray = [];
            for(let h = 0; h < 570 ; h+= 30 ){  //height
                for(let w = 0; w < 570 ; w+= 30 ){ //width
                    if(!excludeBox.includes(`${h/30},${w/30}`)){
                        particleArray.push(new Particle(w,h));
                    }
                }  
            } 
        }
        init();
        function animate(){
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for(let i=0; i< particleArray.length ; i++){
                particleArray[i].draw();
                particleArray[i].update();
            }
            requestAnimationFrame(animate);
        }
        animate();
    },[])
    return(
        <>
        <div  className='logo-box-container'>
        <canvas style={{
            // backdropFilter: 'blur(4.47587px)'
        }} ref={canvasRef}>
        </canvas>
        <div className='coming-zoon-badge'>
            <img  src={require('../assets/images/comingSoonPadge.png')} />
        </div>
        <div className='fly-logo'>
        <img  src={require('../assets/images/flytalklogo.png')} />
        </div>
        </div> 
            {/* {Boxes} */}
        </>
    )
}

export default LogoBox;