import React from 'react';
import './joinus.style.css';
import { InstagramLogo,LinkedinLogo,TwitterLogo } from '@phosphor-icons/react';
import { jobsList } from '../../constants/constants';
import { Accordion, AccordionSummary, AccordionDetails }  from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MapPin } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import GridLines from 'react-gridlines';

const JoinUs = () => {
    const naivgate = useNavigate();
    const onLogoClick = ()=>{
        naivgate("/")
    }
    
    return(
        <div className='homemaincon'>
        <GridLines  lineColor="#171717" lineColor2="#171717" className="grid-area" cellWidth={30} strokeWidth={2} cellWidth2={31}>
        <div className='joinus-contaier' >
            <img onClick={onLogoClick} className='home-logo-img' src={require('../../assets/logo.png')} />
            <div className='openings-container'>
                <div className='joinus-text'>
                    <p >Join</p>
                    <p >our team!</p>
                </div>
                <div className='openings-list-container'>
                    <p className='current-opening-text'>Current opening</p>
                    {jobsList.map(item =>{
                        return (
                            <div key={item.id} className='single-opening-container'>
                                <Accordion className='accordion-container' >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon color='#00D3E2' style={{color:"#00D3E2",width:38,height:38}} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className='accordion-summary'
                                        >
                                      <div className='single-opening'>
                                            <p className='opening-title-text'>{item.title}</p>
                                            <p className='experiance-text'>{item.experience}</p>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <p className='opening-content'>{item.content}</p>
                                    <div className='apply-btn-main'>
                                        <div className='flex-1' />
                                        <div className='apply-btn'>
                                            <p className='apply-btn-text'>Apply now</p>
                                        </div>
                                    </div>
                                    </AccordionDetails>
                                </Accordion>
                          
                        </div>
                        )
                    })}
                   
                </div>
            </div>
            <div className='location-container'>
            <MapPin size={24} weight="fill" color='#fff' />
                    <div className='location-address-container' >
                        <p className='location-title'>Concord Tower</p>
                        <p className='location-line'>Dubai Media City,</p>
                        <p className='location-line'>Al Sufouh 2, Al Sufouh, Jumeirah, Dubai.</p>
                    </div>
            </div>
        </div>
       </GridLines>
       </div>
        
    )
}


export default JoinUs;