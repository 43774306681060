import React from 'react';
// import './privacy.style.css';
import { InstagramLogo,LinkedinLogo,TwitterLogo } from '@phosphor-icons/react';
import { jobsList } from '../../constants/constants';
import { Accordion, AccordionSummary, AccordionDetails }  from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MapPin } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import GridLines from 'react-gridlines';
import privacyData from '../../constants/privacyPolicy.json';
const PrivacyPolicy = () => {
    const naivgate = useNavigate();
    const onLogoClick = ()=>{
        naivgate("/")
    }
    
    return(
        <div className='homemaincon'>
        {/* <GridLines  lineColor="#171717" lineColor2="#171717" className="grid-area" cellWidth={30} strokeWidth={2} cellWidth2={31}> */}
        <div className='joinus-contaier' >
            <img onClick={onLogoClick} className='home-logo-img' src={require('../../assets/logo.png')} />
            <div className=''>
            <p className='current-opening-text mt-48' style={{fontSize:34}}>Privacy Policy</p>
            <p className='current-opening-text mt-48'>1. General Information</p>
            <p className='content-text'>{privacyData.info1}</p>
            <p className='content-text'>{privacyData.info2}</p>
            <p className='current-opening-text mt-48'>2. Data Controller</p>
            <p className='content-text'>{privacyData.Controller}</p>

            <p className='current-opening-text mt-48'>3. Data Processing in Connection with FlyTalk</p>
            <p className='current-opening-text mt-48' style={{fontSize:18}}>3.1 Visiting FlyTalk</p>
            <p className='content-text'>{privacyData.Visiting}</p>
            <p className='content-text'>The following technical data may be collected:</p>
            {privacyData.collected.map(item =>{
                return(
                    <p className='content-text' style={{marginLeft:30}}>• {item}</p>  
                )
            })}
            <p className='content-text'>This data is automatically deleted after no later than two days.</p>
            <p className='current-opening-text mt-48' style={{fontSize:18}}>3.2 Collection of User Data</p>
            <p className='content-text'>{privacyData.Collection}</p>
            <p className='content-text'>The following types of information may be collected:</p>

  {privacyData.collected2.map(item =>{
                return(
                    <p className='content-text' style={{marginLeft:30}}>• {item}</p>  
                )
            })}
            <p className='content-text'>We collect this information during specific circumstances, such as:</p>
            {privacyData.collected3.map(item =>{
                return(
                    <p className='content-text' style={{marginLeft:30}}>• {item}</p>  
                )
            })}

        <p className='current-opening-text mt-48'>User Data Storage and Use</p>
        <p className='content-text'>{privacyData.Storage}</p>
        <p className='current-opening-text mt-48'>Your Choices and Rights</p>
        <p className='content-text'>{privacyData.Rights}</p>
        <p className='current-opening-text mt-48'>Updating Your Information</p>
        <p className='content-text'>{privacyData.Updating}</p>
        <p className='current-opening-text mt-48'>Security Measures</p>
        <p className='content-text'>{privacyData.Measures}</p>
        <p className='current-opening-text mt-48'>Profiling and Newsletters</p>
        <p className='content-text'>{privacyData.Profiling}</p>
        <p className='current-opening-text mt-48'>Changes to this Privacy Policy</p>
        <p className='content-text'>{privacyData.Changes}</p>
            </div>
        </div>
      
       </div>
        
    )
}


export default PrivacyPolicy;