import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import JoinUs from './pages/JoinUs/JoinUsPage';
import HomePage from './pages/Home/HomePage';
import AnimatedView from './pages/Home/ParticleAnima';
import AboutUs from './pages/aboutus/AboutUs';
import ContactUs from './pages/contactUs/contactUs';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy';
import TermsOfUse from './pages/privacyPolicy/termsofUse';
import UserAgreement from './pages/privacyPolicy/userAgreement';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "join-us",
    element: <JoinUs />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "terms-of-use",
    element: <TermsOfUse />,
  },
  {
    path: "user-agreement",
    element: <UserAgreement />,
  },
  {
    path: "anima",
    element: <AnimatedView />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);