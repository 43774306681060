import React from 'react';

import { useNavigate } from 'react-router-dom';
import userAgreementData from '../../constants/userAgreement.json'
const UserAgreement = () => {
    const naivgate = useNavigate();
    const onLogoClick = ()=>{
        naivgate("/")
    }
    
    return(
        <div className='homemaincon'>
        <div className='joinus-contaier' >
            <img onClick={onLogoClick} className='home-logo-img' src={require('../../assets/logo.png')} />
            <div className=''>
            <p className='current-opening-text mt-48'>User Agreement</p>
            <p className='content-text'>{userAgreementData.main} </p>
            <p className='current-opening-text mt-48'>1. General Usage </p>
            <p className='current-opening-text'>1.1 Eligibility </p>
            <p className='content-text'>{userAgreementData.Eligibility} </p>
            <p className='current-opening-text'>1.2 Amendments  </p>
            <p className='content-text'>{userAgreementData.Amendments} </p>
            <p className='current-opening-text'>2. Compliance with Applicable Regulations   </p>
            <p className='content-text'>{userAgreementData.Regulations} </p>
            <p className='current-opening-text'>3. Access to the Services   </p>
            <p className='current-opening-text'>3.1 Limited Authorization   </p>
            <p className='content-text'>{userAgreementData.Authorization} </p>
            <p className='current-opening-text'>3.2 Confidentiality   </p>
            <p className='content-text'>{userAgreementData.Confidentiality} </p>
            <p className='current-opening-text'>3.3 Adherence</p>
            <p className='content-text'>{userAgreementData.Adherence} </p>
            <p className='current-opening-text'>3.4 Communication</p>
            <p className='content-text'>{userAgreementData.Communication} </p>
            <p className='current-opening-text'>4. Risks of Digital Assets </p>
            <p className='content-text'>{userAgreementData.Risks} </p>
            <p className='current-opening-text'>5.  General Clauses </p>
            <p className='current-opening-text'>5.1 Intellectual Property</p>
            <p className='content-text'>{userAgreementData.Intellectual511} </p>
            <p className='current-opening-text'>5.2 Information Accuracy </p>
            <p className='content-text'>{userAgreementData.Intellectual512} </p>
            <p className='current-opening-text'>5.3 Third-Party Services and Content</p>
            <p className='content-text'>{userAgreementData.Intellectual513} </p>
            <p className='current-opening-text'>6.  Representations, Warranties, Indemnification, and Liability Limitations </p>
            <p className='current-opening-text'>6.1 Acceptable Use of FlyTalk Services </p>
            <p className='content-text'>{userAgreementData.Acceptable} </p>

            {userAgreementData.AcceptableList.map(item =>{
                return(
                    <div style={{display:'flex'}}>
                         <p className='content-text'>{item.id}. </p>
                         <p className='content-text' style={{marginLeft:16}}>{item.content} </p>
                        </div>
                           
                )
            })}

{userAgreementData.Disclaimers.map(item =>{
                return(
                    <div >
                         <p className='current-opening-text'>{item.title}</p>
                         <p className='content-text' style={{}}>{item.content} </p>
                        </div>
                           
                )
            })}
              <p className='current-opening-text'>Miscellaneous</p>
              {userAgreementData.Miscellaneous.map(item =>{
                return(
                    <div >
                         <p className='current-opening-text'>{item.title}</p>
                         <p className='content-text' style={{}}>{item.content} </p>
                        </div>
                           
                )
            })}
            </div>
        </div>
       </div>
        
    )
}


export default UserAgreement;