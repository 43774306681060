import React from 'react';
import './contactus.style.css';
import { InstagramLogo,LinkedinLogo,TwitterLogo } from '@phosphor-icons/react';
import { jobsList } from '../../constants/constants';
import { Accordion, AccordionSummary, AccordionDetails }  from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MapPin } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import GridLines from 'react-gridlines';

const ContactUs = () => {
    const naivgate = useNavigate();
    const onLogoClick = ()=>{
        naivgate("/")
    }
    
    return(
        <div className='homemaincon'>
        <GridLines  lineColor="#171717" lineColor2="#171717" className="grid-area" cellWidth={30} strokeWidth={2} cellWidth2={31}>
        <div className='joinus-contaier' >
            <img onClick={onLogoClick} className='home-logo-img' src={require('../../assets/logo.png')} />
            <div className=''>
            <p className='current-opening-text mt-48'>Contact Us</p>
            <p className='content-text'>We have highly skilled engineers with excellent technical knowledge and experience in using the latest software standards. We have built a large pool of knowledge that we apply to deliver solutions that meet client's needs, expectations and budget. By putting our clients at the heart of everything we do, we are proud that we are still supporting our very first business client.</p>
            </div>
            <div className='location-container'>
            <MapPin size={24} weight="fill" color='#fff' />
                    <div className='location-address-container' >
                        <p className='location-title'>Concord Tower</p>
                        <p className='location-line'>Dubai Media City,</p>
                        <p className='location-line'>Al Sufouh 2, Al Sufouh, Jumeirah, Dubai.</p>
                    </div>
            </div>
        </div>
       </GridLines>
       </div>
        
    )
}


export default ContactUs;