import React from 'react';
import './privacy.style.css';
import { useNavigate } from 'react-router-dom';
import termsOfuse from '../../constants/termsOfuse.json';
const TermsOfUse = () => {
    const naivgate = useNavigate();
    const onLogoClick = ()=>{
        naivgate("/")
    }
    
    return(
        <div className='homemaincon'>
        <div className='joinus-contaier' >
            <img onClick={onLogoClick} className='home-logo-img' src={require('../../assets/logo.png')} />
            <div className=''>
            <p className='current-opening-text mt-48' style={{fontSize:34}}>Terms of Use</p>
        {termsOfuse.dataList.map(item =>{
            return(
               <div>
                 <p className='current-opening-text mt-48'>{item.title}</p>
            <p className='content-text'>{item.content}</p>
                </div> 
            )
        })}

                {termsOfuse.ProhibitedUsers.map(item =>{
                            return(
                            <p className='content-text' style={{marginLeft:30}}>• {item}</p>
                            )
                        })}

<p className='content-text' style={{}}>You further agree not to:</p>
{termsOfuse.furtherAgree.map(item =>{
                            return(
                            <p className='content-text' style={{marginLeft:30}}>• {item}</p>
                            )
                        })}

{termsOfuse.dataList2.map(item =>{
            return(
               <div>
                 <p className='current-opening-text mt-48'>{item.title}</p>
            <p className='content-text'>{item.content}</p>
                </div> 
            )
        })}
            </div>
        </div>
       </div>
        
    )
}


export default TermsOfUse;