import React from 'react';
import './aboutus.style.css';
import { InstagramLogo,LinkedinLogo,TwitterLogo } from '@phosphor-icons/react';
import { jobsList } from '../../constants/constants';
import { Accordion, AccordionSummary, AccordionDetails }  from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MapPin } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import GridLines from 'react-gridlines';
import AboutUsJson from '../../constants/aboutus.json';


const AboutUs = () => {
    const naivgate = useNavigate();
    const onLogoClick = ()=>{
        naivgate("/")
    }
    
    return(
        <div className='homemaincon'>
        <GridLines  lineColor="#171717" lineColor2="#171717" className="grid-area" cellWidth={30} strokeWidth={2} cellWidth2={31}>
        <div className='joinus-contaier' >
            <img onClick={onLogoClick} className='home-logo-img' src={require('../../assets/logo.png')} />
            <div className=''>
            <p className='current-opening-text mt-48' style={{fontSize:34}} >About Us</p>
            <p className='current-opening-text'>Shaping Tomorrow with Skyland and FlyTalk</p>
            <p className='content-text'>{AboutUsJson.main}</p>
            <p className='current-opening-text'>Our Vision: Redefining Possibilities</p>
            <p className='content-text'>{AboutUsJson.Vision}</p>

            <p className='current-opening-text'>Our Mission: Innovate, Elevate, Empower </p>
            <p className='content-text'>{AboutUsJson.Mission}</p>


            <p className='current-opening-text'>Our Approach: Collaborate for Impact  </p>
            <p className='content-text'>{AboutUsJson.Approch}</p>

            <p className='current-opening-text'>Why Skyland?</p>
          
            {AboutUsJson.whySkyland.map(item =>{
                return(
                    <div style={{marginLeft:30}}>
                          <p className='current-opening-text'>{item.title}</p>
            <p className='content-text'>{item.content}</p>
                        </div>
                )
            })}
              <p className='current-opening-text'>Our Portfolios: Fly high with FlyTalk </p>
            <p className='content-text'>{AboutUsJson.Portfolios}</p>
              <p className='current-opening-text'>Join Skyland and FlyTalk: Shaping tomorrow </p>
            <p className='content-text'>{AboutUsJson.Join}</p>
            </div>
            {/* <div className='location-container'>
            <MapPin size={24} weight="fill" color='#fff' />
                    <div className='location-address-container' >
                        <p className='location-title'>Concord Tower</p>
                        <p className='location-line'>Dubai Media City,</p>
                        <p className='location-line'>Al Sufouh 2, Al Sufouh, Jumeirah, Dubai.</p>
                    </div>
            </div> */}
        </div>
       </GridLines>
       </div>
        
    )
}


export default AboutUs;